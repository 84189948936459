import { useQuery } from '@tanstack/react-query';

import { photoApi } from '~/shared/services/api';

export const GET_PROFILE_PICTURE_QUERY_KEY = 'useGetProfilePicture';

async function getProfilePicture(globalId?: string) {
  if (!globalId) return null;
  const response = await photoApi.get<Blob>(`${globalId}`, {
    responseType: 'blob',
  });

  return response;
}

export function useGetProfilePicture(globalId: string, enabled = true) {
  return useQuery({
    queryKey: [GET_PROFILE_PICTURE_QUERY_KEY, globalId],
    queryFn: () => getProfilePicture(globalId),
    select: data => data?.data,
    enabled,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
