/* eslint-disable no-console */
import * as React from 'react';
import { Avatar as DSAvatar } from '@ghq-abi/design-system';

import { useGetProfilePicture } from '../../../entities/Employee/api/useGetProfilePicture';

type AvatarProps = React.ComponentProps<typeof DSAvatar> & {
  employeeId?: string;
  globalId?: string;
};

export function Avatar({ employeeId, globalId, ...rest }: AvatarProps) {
  const { data } = useGetProfilePicture(globalId ?? '');
  const imageSrc = data ? URL.createObjectURL(data) : undefined;

  return (
    <DSAvatar
      src={(employeeId || globalId) && imageSrc ? imageSrc : undefined}
      {...rest}
    />
  );
}
